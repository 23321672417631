import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Webcam from 'react-webcam';

export default class AvatarWebcam extends Component {
    constructor(props) {
        super(props);

        // "mode" determines which view/state we're in:
        //  1) 'viewing'   - showing existing avatar image, camera off
        //  2) 'previewing'- camera on (live feed)
        //  3) 'captured'  - camera off, showing captured image
        this.state = {
            mode: 'viewing',
            capturedImage: null
        };

        this.webcamRef = React.createRef();

        // Bind methods
        this.activateCamera = this.activateCamera.bind(this);
        this.deactivateCamera = this.deactivateCamera.bind(this);
        this.capture = this.capture.bind(this);
        this.discardCapture = this.discardCapture.bind(this);
        this.saveCapture = this.saveCapture.bind(this);
        this.retake = this.retake.bind(this);
    }

    // ------------- MODE HANDLERS ----------------- //
    activateCamera() {
        this.setState({ mode: 'previewing', capturedImage: null });
    }

    deactivateCamera() {
        this.setState({ mode: 'viewing', capturedImage: null });
    }

    discardCapture() {
        this.setState({ mode: 'viewing', capturedImage: null });
    }

    retake() {
        this.setState({ mode: 'previewing', capturedImage: null });
    }

    saveCapture() {
        // Move the captured image into the official `avatar` prop
        const { setProps } = this.props;
        const { capturedImage } = this.state;
        if (capturedImage && setProps) {
            setProps({ avatar: capturedImage });
        }
        // Return to viewing mode
        this.setState({ mode: 'viewing', capturedImage: null });
    }

    // ------------- CAPTURE (CIRCLE-CROP) ---------------- //
    capture() {
        const { avatarSize } = this.props;
        if (!this.webcamRef.current) {
            return;
        }

        // Grab a screenshot from react-webcam
        const screenshotData = this.webcamRef.current.getScreenshot();
        if (!screenshotData) {
            return;
        }

        // Circle-crop in an off-screen canvas
        const image = new Image();
        image.src = screenshotData;
        image.onload = () => {
            const diameter = avatarSize;
            const canvas = document.createElement('canvas');
            canvas.width = diameter;
            canvas.height = diameter;

            const ctx = canvas.getContext('2d');

            // Create a circular clipping path
            ctx.beginPath();
            ctx.arc(diameter / 2, diameter / 2, diameter / 2, 0, 2 * Math.PI);
            ctx.closePath();
            ctx.clip();

            // COVER logic (no distortion):
            // 1) Figure out which dimension (width or height) is limiting
            //    so that we fill the circle fully without squishing.
            const imgWidth = image.width;
            const imgHeight = image.height;
            const imgRatio = imgWidth / imgHeight;     // e.g. 1.333 for 4:3
            const circleRatio = 1; // diameter:diameter => 1

            let newWidth, newHeight, offsetX, offsetY;
            if (imgRatio > circleRatio) {
                // image is "wider" than our circle => match circle's height
                newHeight = diameter;
                newWidth = imgRatio * diameter;  // scale width by same factor
                offsetX = (diameter - newWidth) / 2;
                offsetY = 0;
            } else {
                // image is "taller" or equal => match circle's width
                newWidth = diameter;
                newHeight = diameter / imgRatio;
                offsetX = 0;
                offsetY = (diameter - newHeight) / 2;
            }

            // Draw the image so it *covers* the circle
            ctx.drawImage(image, offsetX, offsetY, newWidth, newHeight);

            // Convert canvas to base64 PNG
            const croppedData = canvas.toDataURL('image/png');
            this.setState({
                mode: 'captured',
                capturedImage: croppedData
            });
        };
    }

    // ------------- RENDER ----------------- //
    render() {
        const { mode, capturedImage } = this.state;
        const { id, className, style, avatarSize, avatar } = this.props;

        // If we have an official avatar in props, display it in "viewing" mode
        const currentAvatar = avatar || null;

        // Common container & circle styling
        const containerStyle = {
            position: 'relative',
            width: avatarSize,
            height: avatarSize
        };
        const circleStyle = {
            width: avatarSize,
            height: avatarSize,
            borderRadius: '50%',
            overflow: 'hidden',
            position: 'relative',
            backgroundColor: '#eee'
        };

        // Overlay button base style
        const overlayButtonStyle = {
            position: 'absolute',
            backgroundColor: 'rgba(0,0,0,0.6)',
            color: 'white',
            border: 'none',
            padding: '6px 10px',
            cursor: 'pointer',
            fontSize: '14px'
        };
        // Specific positions
        const closeButtonStyle = {
            ...overlayButtonStyle,
            top: '10px',
            left: '10px'
        };
        const bottomCenterButtonStyle = {
            ...overlayButtonStyle,
            bottom: '10px',
            left: '50%',
            transform: 'translateX(-50%)'
        };
        const bottomLeftButtonStyle = {
            ...overlayButtonStyle,
            bottom: '10px',
            left: '10px'
        };
        const bottomRightButtonStyle = {
            ...overlayButtonStyle,
            bottom: '10px',
            right: '10px'
        };

        // 1) VIEWING
        if (mode === 'viewing') {
            return (
                <div id={id} className={className} style={{...style, ...containerStyle}}>
                    <div style={circleStyle}>
                        {currentAvatar ? (
                            <img
                                src={currentAvatar}
                                alt="Current Avatar"
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover'
                                }}
                            />
                        ) : (
                            <div style={{ width: '100%', height: '100%', backgroundColor: '#ccc' }} />
                        )}
                    </div>
                    {/* Camera button to activate */}
                    <button
                        type="button"
                        style={bottomCenterButtonStyle}
                        onClick={this.activateCamera}
                    >
                        <i className="fa fa-camera" /> Camera
                    </button>
                </div>
            );
        }

        // 2) PREVIEWING
        if (mode === 'previewing') {
            return (
                <div id={id} className={className} style={{...style, ...containerStyle}}>
                    <div style={circleStyle}>
                        {/* 
                          The challenge: react-webcam may not fully support "objectFit" 
                          on its <video> directly. We'll try styling the container or override. 
                        */}
                        <Webcam
                            ref={this.webcamRef}
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover'  // Attempt to avoid stretching
                            }}
                            // Or you can use videoConstraints if needed:
                            // videoConstraints={{ aspectRatio: 1 }} 
                        />
                    </div>
                    {/* X: Cancel */}
                    <button
                        type="button"
                        style={closeButtonStyle}
                        onClick={this.deactivateCamera}
                    >
                        X
                    </button>
                    {/* Capture */}
                    <button
                        type="button"
                        style={bottomCenterButtonStyle}
                        onClick={this.capture}
                    >
                        Capture
                    </button>
                </div>
            );
        }

        // 3) CAPTURED
        if (mode === 'captured') {
            return (
                <div id={id} className={className} style={{...style, ...containerStyle}}>
                    <div style={circleStyle}>
                        <img
                            src={capturedImage}
                            alt="Captured"
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover'
                            }}
                        />
                    </div>
                    {/* X -> discard */}
                    <button
                        type="button"
                        style={closeButtonStyle}
                        onClick={this.discardCapture}
                    >
                        X
                    </button>
                    {/* Retake */}
                    <button
                        type="button"
                        style={bottomLeftButtonStyle}
                        onClick={this.retake}
                    >
                        Retake
                    </button>
                    {/* Save */}
                    <button
                        type="button"
                        style={bottomRightButtonStyle}
                        onClick={this.saveCapture}
                    >
                        Save
                    </button>
                </div>
            );
        }

        // Fallback
        return null;
    }
}

AvatarWebcam.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,

    /**
     * The official "avatar" image, either a data URL (base64)
     * or any image URL. Displayed when camera is off.
     * Updated when user saves a new capture.
     */
    avatar: PropTypes.string,

    /**
     * The circular capture area diameter in pixels.
     */
    avatarSize: PropTypes.number,

    /**
     * Dash callback setter.
     */
    setProps: PropTypes.func
};

AvatarWebcam.defaultProps = {
    avatarSize: 200
};
