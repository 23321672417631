import React from 'react';
import PropTypes from 'prop-types';
import { Scanner } from '@yudiel/react-qr-scanner';

/**
 * TDBQRScanner
 * A QR code scanner component for Dash.
 */
const TDBQRScanner = (props) => {
    const {
        id,
        style,
        className,
        onScan,
        onError,
        constraints,
        formats,
        paused,
        children,
        components,
        styles,
        classNames,
        allowMultiple,
        scanDelay,
        setProps,
    } = props;

    const handleScan = (detectedCodes) => {
        if (setProps) {
            setProps({ detectedCodes });
        }
        if (onScan) {
            onScan(detectedCodes);
        }
    };

    const handleError = (error) => {
        console.info(error)
        if (setProps) {
            setProps({ error: String(error) });
        }
        if (onError) {
            onError(error);
        }
    };

    return (
        <div id={id} className={className} style={style}>
            <Scanner
                onScan={handleScan}
                onError={handleError}
                constraints={constraints}
                formats={formats}
                paused={paused}
                components={components}
                styles={styles}
                classNames={classNames}
                allowMultiple={allowMultiple}
                scanDelay={scanDelay}
            >
                {children}
            </Scanner>
        </div>
    );
};

TDBQRScanner.defaultProps = {
    className: null,
    style: null,
    paused: false,
    formats: ['qr_code'],
    allowMultiple: false,
    scanDelay: 500,
    children: null,
    onScan: null,
    onError: null,
    constraints: null,
    components: null,
    styles: null,
    classNames: null,
    setProps: () => {},
};

TDBQRScanner.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.string,
    onScan: PropTypes.func,
    onError: PropTypes.func,
    constraints: PropTypes.object,
    formats: PropTypes.arrayOf(PropTypes.string),
    paused: PropTypes.bool,
    children: PropTypes.node,
    components: PropTypes.object,
    styles: PropTypes.object,
    classNames: PropTypes.object,
    allowMultiple: PropTypes.bool,
    scanDelay: PropTypes.number,
    setProps: PropTypes.func,
    detectedCodes: PropTypes.arrayOf(
        PropTypes.shape({
            format: PropTypes.string,
            text: PropTypes.string,
        })
    ),
    error: PropTypes.string,
};

export default TDBQRScanner;
